import { FC } from 'react'
import styled from 'styled-components'

import UserAccessProvider from './provider/UserAccessProvider'
import UserAccessModule from './UserAccessModule'

const UserAccess: FC = (props) => (
  <UserAccessProvider>
    <StyledWrapper className='no-margin-top pb-6'>
      <UserAccessModule {...props} />
    </StyledWrapper>
  </UserAccessProvider>
)

const StyledWrapper = styled.div`
  min-height: 796px;
  background: var(--bs-mch-gray-100);

  @media (max-width: ${global.uikitVars.breakpointSmMax}) {
    min-height: 755px;
  }
`

export default UserAccess